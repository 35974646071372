import * as styles from "./ExplainerVideos.module.scss";
import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { FiSearch } from "react-icons/fi";
import VideoThumbnail from "../../molecules/VideoThumbnail/VideoThumbnail";
import Close from "../../../svg/close.svg";
import { explainerTags } from "../../../constants/tagsData";
import { data } from "../../../constants/explainervideosData";
import ArrowRight from "../../../svg/icon-arrow-right.svg";
import ArrowLeft from "../../../svg/icon-arrow-left.svg";
function ExplainerVideos({extraClass}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedVideo, setSelectedVideo] = useState(null);

  function handleSearchChange(event) {
    setSearchTerm(event.target.value);
  }

  const videoResults = data.filter(
    (result) =>
      result.serie.toLowerCase().includes(searchTerm.toLowerCase()) ||
      result.videos.some(video => video.title.toLowerCase().includes(searchTerm.toLowerCase()))
  );
  function handleClear() {
    setSearchTerm("");
  }
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow:<div><ArrowLeft/></div>,
    nextArrow:<div><ArrowRight /></div>,
    fade: false,
    accessibility: true,
    speed:0,
  };
  const [selectedButton, setSelectedButton] = useState('See All');

const filteredData = videoResults.filter(item => {
  if (selectedButton === 'See All') {
    return true;
  } else {
    return item.tab === selectedButton;
  }
});

const filteredTags = explainerTags.filter(item => {
  if (selectedButton === 'See All') {
    return true;
  } else {
    return item.tab === selectedButton;
  }
});
  return (
    <>
      <div
        className={extraClass? `${styles.container} ${styles[extraClass]}` : `${styles.container} ${styles.videocontainer}   ${
          offset >= 1000 && `${styles.container1}`
        } ${offset >= 1800 && `${styles.container2}`} ${
          offset >= 4000 && `${styles.container3}`
        } ${offset >= 5000 && `${styles.container4}`}  `}
      >

        <div className={extraClass!=="" ? `${styles.inputgroup} ${styles.usersInput}`: styles.inputgroup }>
          <FiSearch />
          <input
            value={searchTerm}
            onChange={handleSearchChange}
            type={"text"}
            placeholder="Video Search..."
          />
          {searchTerm !== "" && (
            <button onClick={handleClear} className={styles.show}>
              Show All
            </button>
          )}
        </div>
        <div className={styles.maintabs}>
  <button className={selectedButton==="See All" ? styles.selected : ""} onClick={() => {setSelectedButton('See All');setSearchTerm("")}}>See All</button>
  <button className={selectedButton==="License type" ? styles.selected : ""}  onClick={() => setSelectedButton('License type')}>License type</button>
  <button className={selectedButton==="Product modules" ? styles.selected : ""}  onClick={() => setSelectedButton('Product modules')}>Product modules</button>
  <button className={selectedButton==="Target groups" ? styles.selected : ""}  onClick={() => setSelectedButton('Target groups')}>Target groups</button>
  <button className={selectedButton==="User level" ? styles.selected : ""}  onClick={() => setSelectedButton('User level')}>User level</button>
  <button className={selectedButton==="Other" ? styles.selected : ""}  onClick={() => setSelectedButton('Other')}>Other</button>

</div>
        <div className={selectedButton==="See All" ? `${styles.tags} ${styles.allTags}` : styles.tags}>
          <button
            className={styles.searchIcon}
            onClick={() => setSearchTerm("")}
          >
            <FiSearch /> Show All
          </button>
          {filteredTags.map((item, i) => {
            return (
              <button
                key={i}
                className={ 
                  searchTerm?.includes(item.term) ? `${item.extraClass} ${styles.activeTerm}` : item.extraClass
                }  
                onClick={() => setSearchTerm(item.term)}
              >
                {item.tag}
              </button>
            );
          })}
        </div>
        <div className={styles.text}>
          {searchTerm === "social media" ? (
            <p>
              Primarily videos posted in Facebook and social media - getting
              over 2 million views
            </p>
          ) : searchTerm === "visual story" ? (
            <p>Videos expalining our visual story manuals</p>
          ) : searchTerm === "Show Room Series" ? (
            <p>Introduction videos part of our Show Room</p>
          ) : searchTerm === "library" ? (
            <p>Library is one of our key product modules</p>
          ) : searchTerm === "studio" ? (
            <p>Studio is one of our key product modules</p>
          ) : searchTerm === "product" ? (
            <p>All our products accessible in one overview</p>
          ) : (
            ""
          )}
        </div>

        <div className={selectedButton==="See All" ? `${styles.main} ${styles.allData}` : styles.main}>
        {filteredData.map((result) => {
        return (
            <div className={`${styles.serie} exp-videos`}>
                <h6>{result?.serie} ({result?.videos?.length})</h6>
                {/* <Slider {...settings}> */}
                <div className={styles.data}>
                {
                    result.videos.map((video) => 
                            <div className={styles.thumbnail} onClick={() => setSelectedVideo(video)}>
                              <VideoThumbnail
                                image={video?.thumbnail}
                                desc={video?.description}
                                tag={video?.category}
                                extraClass={result?.extraClass  ? result?.extraClass : `expvideo`}
                              />
                            </div>
                        )
                }
                </div>
            </div>
        )
    })
}
        </div>
        {videoResults?.length === 0 && (
          <div className={styles.nocontent}>
            <h3>
              No Content Found with <span>"{searchTerm}"</span>
            </h3>
          </div>
        )}
        <div className={styles.bottomD}>
          <p>
            {" "}
            <br /> Keep having fun with advancing, welcome back<br />{" "}
          </p>
        </div>
        <div className={styles.bottom}></div>
        <div className={styles.ref}>
          <Link to="/videos">
            <p>
              Check out our  <span> Promo Videos</span>
            </p>
          </Link>
        </div>
      </div>
      {selectedVideo && (
        <div className={styles.popup}>
          <button onClick={() => setSelectedVideo(null)}>
            <Close />
          </button>
          <div className={styles.frame}>
            <video
              src={selectedVideo?.url}
              poster={selectedVideo?.poster}
              autoPlay={false}
              controls={true}
            />
          </div>
        </div>
      )}
    </>
  );
}
export default ExplainerVideos;
