// extracted by mini-css-extract-plugin
export var behindImg = "ExplainerBanner-module--behindImg--e2026";
export var content = "ExplainerBanner-module--content--b2985";
export var demoBanner = "ExplainerBanner-module--demoBanner--648e9";
export var desc = "ExplainerBanner-module--desc--98f44";
export var discover = "ExplainerBanner-module--discover--30b35";
export var image = "ExplainerBanner-module--image--617fe";
export var imageTop = "ExplainerBanner-module--imageTop--09c9e";
export var info = "ExplainerBanner-module--info--94666";
export var main = "ExplainerBanner-module--main--823a9";
export var maintitle = "ExplainerBanner-module--maintitle--6197e";
export var slider = "ExplainerBanner-module--slider--9da4c";
export var sneak = "ExplainerBanner-module--sneak--a032f";
export var space = "ExplainerBanner-module--space--8f40e";
export var spanheading = "ExplainerBanner-module--spanheading--eb4e5";
export var videos = "ExplainerBanner-module--videos--e1c3c";