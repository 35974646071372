import React from "react";
import * as styles from "./ExplainerBanner.module.scss";


function ExplainerBanner() {

  return (
    <div className={styles.main}>
      <div className={styles.image} />
      <div className={styles.content}>
          <h1 className={styles.maintitle}>Explorer Videos</h1>
          <p className={styles.info}>Ideas to inspire and advance</p>
        </div>
        </div>
    );
}
export default ExplainerBanner;
