import React from "react";
import * as styles from "./Getwizrfooter.module.scss";

function Getwizrfooter(){
    return(
        <div className={styles.container}>
            <div className={styles.btn}>
                <a aria-label="redirect" href="mailto:sales@mwizr.com"><button aria-label="">Contact us today</button></a>
            </div>
            <div className={styles.links}>
                <div className={styles.link}>
                    <h2>MASTER WiZR</h2>
                    <p>Copyright, all rights reserved, 2023</p>
                </div>
                <div className={styles.center}>
                    <p>License Terms</p>
                    <p>Tech Spec</p>
                    <p>Terminology</p>

                </div>
                <div className={styles.link}>
                    <h2>MASTER WiZR home</h2>
                   <a aria-label="redirect" href="mailto:sales@masterwizr.com"><p>sales@masterwizr.com</p></a>
                </div>
            </div>
        </div>
    );
}
export default Getwizrfooter;