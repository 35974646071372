import * as styles from "./styles.module.scss";
import React from "react";
import logo from "../../../images/getwizr2.png";
import Logo from '../../../svg/masterwizr-logo-footer.svg';
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
function GetWiZRHeader({nolinks,noLogo}){

  const data = [
    {
      link:"Home",
      url:"/getwizr"
    },
    {
      link:"User Inspirations",
      url:"/user-inspirations/"
    },
    {
      link:"Show Room",
      url:"/demoX"
    },
    {
      link:"Plans",
      url:"/pricing-y"
    },
  ]
  const location = useLocation();
  return(
    <div
    className={ location?.pathname==="/user-inspirations/" || location?.pathname==="/explainer-videos" ? `${styles.header} getwizrheader` : `${styles.header} ${styles.fixedHeader} getwizrheader`}
    >
     <div className={styles.container}>
   {!noLogo &&   <Link to="/getwizr"> <img alt="logo" src={logo} /></Link>}
     {!nolinks  &&
      <div className={styles.links}>
      {data.map((item,i) =>{
        return(
          <Link activeClassName="activegetwizr" key={i} to={item.url}>{item.link}</Link>
        )
      })}
    </div>
     }
     </div>
     <a aria-label="redirect" target={"_blank"} className={styles.right} href="/"> <Logo /></a>
    </div>
  );
}
export default GetWiZRHeader;