import React from "react";
import Getwizrfooter from "../components/atoms/GetWizrFooter/Getwizrfooter";
import GetWiZRHeader from "../components/atoms/Getwizrheader";
import ExplainerBanner from "../components/molecules/ExplainerBanner/ExplainerBanner";
import ExplainerVideosContainer from "../components/ogranisms/ExplainerVideos/ExplainerVideos";

function ExplainerVideos() {
  return (
    <>
      {/* <Header /> */}
      <GetWiZRHeader />
      <ExplainerBanner />
      <ExplainerVideosContainer />
      <Getwizrfooter />
    </>
  );
}
export default ExplainerVideos;
