export const articlesTags = [
  "Excellence",
  "Presentation",
  "Design",
  "Art",
  "Productivity",
  "Diversity",
  "Remote work",
  "Virtual meetings",
  "Time",
  "Storytelling",
  "Digital",
  "Creativity",
  "Team Management",
  "Lifestyle",
  "Get WiZR"
];

export const videosTags = [
  {
    tag: "All MW Products",
    term: "product",
  },
  {
    tag: "Intro Videos",
    term: "Intro Video",
  },
  {
    tag: "Social Media posts",
    term: "social media",
  },
  // {
  //   tag: "Visual Story",
  //   term: "visual story",
  // },
  {
    tag: "Show Room Series",
    term: "Show Room Series",
  },
  {
    tag: "Library",
    term: "library",
  },
  {
    tag: "Studio",
    term: "studio",
  },
];

export const explainerTags = [
  {
    tag: "Student License",
    term: "Student License",
    extraClass:"orangetag",
    tab:"License type"
  },
  {
    tag: "Personal License",
    term: "Personal License",
    extraClass:"orangetag",
    tab:"License type"
  },
  {
    tag: "Professional License",
    term: "Professional License",
    extraClass:"orangetag",
    tab:"License type"
  },
  {
    tag: "Overviews & Tech",
    term: "Overviews & Tech",
    extraClass:"orangetag",
    tab:"Target groups"
  },
  {
    tag: "Corporate",
    term: "Corporate",
    extraClass:"orangetag",
    tab:"Target groups"
  },
  {
    tag: "Organization",
    term: "Organization",
    extraClass:"orangetag",
    tab:"Target groups"
  },
  {
    tag: "Startups",
    term: "Startups",
    extraClass:"orangetag",
    tab:"Target groups"
  },
  {
    tag: "Creatives",
    term: "Creatives",
    extraClass:"orangetag",
    tab:"Target groups"
  },
  {
    tag: "Personal use",
    term: "Personal use",
    extraClass:"orangetag",
    tab:"Target groups"
  },
  {
    tag: "Sports",
    term: "Sports",
    extraClass:"orangetag",
    tab:"Target groups"
  },
  {
    tag: "Beginner",
    term: "Beginner",
    extraClass:"greentag",
    tab:"User level"
  },
  {
    tag: "Advanced",
    term: "Advanced",
    extraClass:"greentag",
    tab:"User level"
  },
  {
    tag: "Library",
    term: "Library",
    extraClass:"bluetag",
    tab:"Product modules"
  },
  {
    tag: "Studio",
    term: "Studio",
    extraClass:"bluetag",
    tab:"Product modules"
  },
  {
    tag: "WiZR Connect",
    term: "WiZR Connect",
    extraClass:"bluetag",
    tab:"Product modules"
  },
  {
    tag: "WiZR X",
    term: "WiZR X",
    extraClass:"bluetag",
    tab:"Product modules"
  },
  {
    tag: "MPublisher",
    term: "MPublisher",
    extraClass:"bluetag",
    tab:"Product modules"
  },
  {
    tag: "Getting started",
    term: "Getting started",
    extraClass:"purpletag",
    tab:"Other"
  },
  {
    tag: "Visual inspirations",
    term: "Visual inspirations",
    extraClass:"purpletag",
    tab:"Other"
    
  },
  {
    tag: "Case Examples",
    term: "Case Examples",
    extraClass:"purpletag",
    tab:"Other"

  },
  {
    tag: "Do it yourself",
    term: "Do it yourself",
    extraClass:"purpletag",
    tab:"Other"

  },
  
];
